import xhrService from "../common/xhrService";
import handleRequestFail from "./handleRequestFail";
import handleRequestSuccess from "./handleRequestSuccess";

const handleSubmit = (contactForm, e) => {
  e.preventDefault();
  contactForm.querySelector("button[type='submit']").setAttribute("disabled", "disabled");
  contactForm.querySelector("button[type='submit']").classList.add("loading");

  const data = {
    name: contactForm.querySelector("[name='name']").value,
    email: contactForm.querySelector("[name='email']").value,
    text: contactForm.querySelector("[name='text']").value,
  };

  xhrService(
    new XMLHttpRequest(),
    handleRequestSuccess(contactForm),
    handleRequestFail(contactForm),
    "POST",
    "https://vssau7haqf.execute-api.ap-northeast-1.amazonaws.com/Prod/",
    [{ key: "Content-Type", value: "application/json" }],
    data,
  );
};

export default handleSubmit;
