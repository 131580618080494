import handleContactSubmit from "./contact/handleSubmit";

window.addEventListener("load", function () {
  const $ = window.$;
  $(".titleWrapper").click(function () {
    const toggle = $(this).next("div#descwrapper");
    $(toggle).slideToggle("slow");
  });
  $(".inactive").click(function () {
    $(this).toggleClass("inactive active");
  });
  $(".active").click(function () {
    $(this).toggleClass("inactive active");
  });

  window.onscroll = function () {
    myFunction();
  };

  const header = document.getElementById("myheader");
  const sticky = 80;

  function myFunction() {
    if (window.pageYOffset > sticky) {
      header.classList.add("sticky");
      header.classList.remove("shadow");
    } else {
      header.classList.remove("sticky");
      header.classList.add("shadow");
    }
  }

  $(".menu-toggle, .nav-m-a").on("click", function () {
    $(".menu-toggle").toggleClass("on");
    $("#nav-overlay").toggleClass("on");
    $("#nav-ul").toggleClass("on");
    $("nav ul").toggleClass("hidden");
    $("nav #nav-overlay").toggleClass("hidden");
    $(".logo-area").toggleClass("on");
    if ($("#nav-overlay").hasClass("hidden")) {
      setTimeout(() => {
        $("nav #nav-overlay").addClass("d-none");
      }, 250);
    } else {
      $("nav #nav-overlay").removeClass("d-none");
    }
  });

  // 問い合わせフォームの送信時のハンドラ設定
  const contactForm = document.getElementById("contact-form");
  if (contactForm) {
    contactForm.addEventListener("submit", (e) => {
      handleContactSubmit(contactForm, e);
    });
  }

  /**
   * 指定された名前のCookieの値を取得する関数
   */
  function getCookie(name) {
    const nameEQ = name + "=";
    const ca = document.cookie.split(";");
    for (let i = 0; i < ca.length; i++) {
      let c = ca[i].trim();
      if (c.indexOf(nameEQ) === 0) return c.substring(nameEQ.length, c.length);
    }
    return "";
  }

  /**
   * Cookieを設定する関数
   * days: 有効期限（日数）
   */
  function setCookie(name, value, days) {
    let expires = "";
    if (days) {
      const date = new Date();
      date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000);
      expires = "; expires=" + date.toUTCString();
    }
    document.cookie = name + "=" + value + expires + "; path=/";
  }

  /**
   * URLの全てのクエリパラメータをCookieに保存する関数
   * 既存のCookieがある場合、delimiterで結合します（デフォルトは「,」）
   */
  function saveAllQueryParamsToCookies(delimiter = ",") {
    const params = new URLSearchParams(window.location.search);
    params.forEach((value, key) => {
      const cookieName = `tryup-q-${key}`;
      const existingValue = getCookie(cookieName);
      const newValue = existingValue ? existingValue + delimiter + value : value;
      // 例としてCookieの有効期限は7日間に設定
      setCookie(cookieName, newValue, 7);
    });
  }

  // 使用例: URLの全てのクエリパラメータをCookieに保存する
  saveAllQueryParamsToCookies();
});
